export const cookieKeys = {
    lang: 'lang',
}

export const events = {
    hamburgerClicked: 'hamburgerclicked',
    cartUpdated: 'carthasbeenupdated',
    showCheckoutPage: 'showcheckoutpage',
    orderSent: 'orderhasbeensent',
    admin: {
        loadProducts: 'loadproducts',
        productVisibilityChanged: 'productvisibilitychanged',
        categoriesSaved: 'categoriessaved',
        orderChangedStatus: 'orderchangedstatus',
        changedOrdersTab: 'changedorderstab',
        productHasBeenSelectedInSearch: 'productisselectedinsearch',
        loadOrders: 'loadorders',
        reviewApproved: 'reviewapproved',
        reviewMarkedAsSpam: 'reviewmarkedasspam',
        reviewReceivedReply: 'reviewreceivedreply',
        uploadedNewImageSlider: 'uploadednewimageslider',
    },
    scrolledToFullDescription: 'scrolledtofulldescription',
    scrolledToReviews: 'scrolledtoreviews',
}

export const storageKeys = {
    cart: 'productsincart',
    cookieConsent: 'SLUAprjRwz',
}

export const constants = {
    defaultDeliveryId: 4,
}
