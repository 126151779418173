<script setup lang="ts">
import { computed } from 'vue'

type Props = {
    icon?: string
    type?: 'outline' | 'solid'
    disabled?: boolean
}

const props = defineProps<Props>()

const classes = computed<string>(() => {
    let result = props.type === 'outline'
        ? 'border border-border text-black hover:border-black hover:text-black'
        : 'bg-main text-white hover:bg-main-hover'

    if (props.disabled) {
        result += ' opacity-50 cursor-not-allowed'
    }

    return result
})
</script>

<template>
    <button
        type="button"
        class="py-2.5 px-6 rounded-md shadow-sm transition-colors"
        :class="classes"
    >
        <span>
            <i v-if="icon" aria-hidden="true" class="mr-3" :class="props.icon"></i>
            <slot />
        </span>
    </button>
</template>
