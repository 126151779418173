<script setup lang="ts">
import { onMounted, ref, watch } from 'vue'

type Props = {
    opened: boolean
    title?: string
    classes?: string
    icon?: string
}

type Emits = {
    (e: 'close'): void
}

const emit = defineEmits<Emits>()
const props = defineProps<Props>()
const isOpen = ref<boolean>(props.opened)

watch(props, (props: Props) => {
    isOpen.value = props.opened
})

onMounted(() => {
    window.addEventListener('keydown', e => {
        if (e.key === 'Escape') {
            emit('close')
        }
    })
})
</script>

<template>
    <div v-if="isOpen" class="fixed inset-0 z-50">
        <div
            @click="emit('close')"
            class="absolute inset-0 z-50 bg-[hsla(0,0%,4%,.8)]"
        ></div>

        <aside class="overflow-y-auto fixed top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 w-full max-w-[1000px] mx-auto z-50">
            <div
                class="bg-page text-font shadow-xl rounded-lg py-7 px-4 lg:p-10 relative mx-2 h-auto min-h-[250px] max-h-[calc(100vh-40px)] overflow-y-auto"
                :class="classes"
            >
                <button
                    @click="emit('close')"
                    type="button"
                    class="absolute top-2 right-2 p-3 text-font-second hover:text-main-pink-hover transition-colors"
                    aria-label="Close contacts modal button"
                >
                    <i class="lni lni-cross-circle text-2xl font-gray-600" aria-hidden="true"></i>
                </button>

                <h2
                    v-if="props.title"
                    class="text-xl lg:text-2xl text-center !mb-6"
                >
                    <i
                        v-if="props.icon"
                        class="mr-3"
                        :class="props.icon"
                        aria-hidden="true"
                    ></i>

                    {{ props.title }}
                </h2>

                <slot />
            </div>
        </aside>
    </div>
</template>
